const isValidJSON = (str: string) => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};

const cookie = {
  /**
   * @description client-side, gets cookie by name from the document.
   * @param {string} name cookie name
   * @param {D} defaultValue default cookie value
   * @return {D} cookie value or default value if not found
   */
  get: <D = unknown>(name: string, defaultValue: D): D => {
    const cookie = document.cookie.match(`(?:(?:^|.*; *)${name} *= *([^;]*).*$)|^.*$`);
    const cookieValue: string | number | null = cookie && cookie?.length > 0 ? cookie[1] : null;
    if (cookieValue) {
      const parsedValue: D = isValidJSON(cookieValue) ? JSON.parse(cookieValue) : cookieValue;
      const decoded =
        typeof parsedValue === 'string' ? decodeURIComponent(parsedValue) : parsedValue;
      return decoded as D;
    } else {
      return defaultValue;
    }
  },
  /**
   * @description client-side, sets a cookie in the document.
   * @param {CookieSetProps} props cookie set properties, including name, value and cookie options
   * @return {void} void; procedure function
   */
  set: ({name, value, options = {}}: CookieSetProps) => {
    /* If options contains days then we're configuring max-age */
    if (options.days) {
      options['max-age'] = options.days * 60 * 60 * 24;

      /* Deleting days from options to pass remaining opts to cookie settings */
      delete options.days;
    }

    /* Configuring options to cookie standard by reducing each property */
    const optionString = Object.entries(options).reduce(
      (accumulatedStr, [k, v]) => `${accumulatedStr}; ${k}=${v}`,
      '',
    );

    /* Finally, creating the key */
    document.cookie = name + '=' + JSON.stringify(value) + optionString;
  },
  /**
   * @description gets cookie by name from a cookie string.
   * @param {string} cookieName name of the cookie.
   * @param {string?} cookieString cookie string that can be `undefined`.
   * @return {string|null} returns the cookie value or null if not found.
   */
  extractFromString: (cookieName: string, cookieString?: string): null | string => {
    if (!cookieString) return null;

    const cookies = cookieString.split(';');

    const pairFound = cookies.find((cookie) => {
      const [key] = cookie.trim().split('=');
      return key === cookieName;
    });
    if (!pairFound) return null;
    /**
     * @description Destructuring the pair found and returning the value.
     * eg. 'cookie1=abc' ---> const [, value] = ['cookie1', 'abc']
     */
    const [, value] = pairFound.trim().split('=');

    return value ? decodeURIComponent(value) : null;
  },
};

export default cookie;

interface CookieSetProps {
  name: string;
  value: string | object;
  options?: {
    days?: number;
    expires?: string;
    path?: string;
    domain?: string;
    secure?: boolean;
  };
}
