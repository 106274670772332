'use client';

import {useRouter} from 'next/router';
import {useEffect} from 'react';

import {isIOS15or16} from './getAppMetadata';

const changeViewportMetaTag = () => {
  document.querySelectorAll('meta[name="viewport"]').forEach((element) => element.remove());
  const meta = document.createElement('meta');
  meta.name = 'viewport';
  meta.content = 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no';
  document.head.appendChild(meta);
};

/**
 * @description This component is used to set the viewport meta tag
 * for `iPhone iOS v.15/v.16` devices preventing zoom behavior on
 * input:focus and maintaining compatibility across devices.
 * @return {null}
 */
const ViewportIOSDevices = () => {
  const router = useRouter();

  /**
   * @description The effect to change the viewport meta tag
   * when the user changes page.
   */
  useEffect(() => {
    if (isIOS15or16(navigator.userAgent)) {
      changeViewportMetaTag();
    }
  }, [router.asPath]);

  return null;
};

export default ViewportIOSDevices;
