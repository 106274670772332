import type {LinkHTMLAttributes, MetaHTMLAttributes} from 'react';

/**
 * @description detects if the device is iOS based on the provided user agent
 * @param {string|undefined} userAgent user agent, optional parameter
 * @return {boolean} - The result of the validation: is iOS or not
 */
export const isIOS = (userAgent?: string) => (userAgent ? /iPad|iPhone/.test(userAgent) : false);

export const isIOS15or16 = (userAgent: string) => /iPhone OS 1[5-6]_/.test(userAgent);

const metaTagsIOS = [
  {
    name: 'viewport',
    content: 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no',
    id: 'app-page-ios-viewport',
  },
];

/**
 * @description generates the full URL for the current page path
 * @param {string} pathname
 * @return {string} full page URL
 */
export const getFullPageUrl = (pathname: string) => {
  let fullPageUrl = `${process.env.HOST}${pathname !== '/home' ? pathname : '/'}`;
  if (!fullPageUrl.endsWith('/')) {
    fullPageUrl = fullPageUrl + '/';
  }
  return fullPageUrl;
};

/**
 * @description generates metadata for the app root level.
 * @param {Object} props
 * @param {string} props.pathname the pathname of the current page
 * @return {Record<string, unknown>} metadata for the app
 */
export const getAppMetadata = ({
  pathname,
  userAgent,
}: {
  pathname: string;
  userAgent?: string;
}): {
  links: (LinkHTMLAttributes<HTMLLinkElement> & {id: string})[];
  metaTags: (MetaHTMLAttributes<HTMLMetaElement> & {id: string})[];
} => {
  const fullPageUrl = getFullPageUrl(pathname);

  return {
    links: [
      {
        id: 'page-alternate-link-en',
        rel: 'alternate',
        href: fullPageUrl,
        hrefLang: 'en',
      },
      {
        id: 'page-alternate-link-en-US',
        rel: 'alternate',
        href: fullPageUrl,
        hrefLang: 'en-US',
      },
      {
        id: 'page-alternate-link-en-GB',
        rel: 'alternate',
        href: fullPageUrl,
        hrefLang: 'en-GB',
      },
      {
        id: 'page-alternate-link-en-IE',
        rel: 'alternate',
        href: fullPageUrl,
        hrefLang: 'en-IE',
      },
      {
        id: 'page-alternate-link-en-CA',
        rel: 'alternate',
        href: fullPageUrl,
        hrefLang: 'en-CA',
      },
      {
        id: 'page-alternate-link-en-AU',
        rel: 'alternate',
        href: fullPageUrl,
        hrefLang: 'en-AU',
      },
      {
        id: 'page-alternate-link-en-NZ',
        rel: 'alternate',
        href: fullPageUrl,
        hrefLang: 'en-NZ',
      },
      {
        id: 'page-alternate-link-en-ZA',
        rel: 'alternate',
        href: fullPageUrl,
        hrefLang: 'en-ZA',
      },
      {
        id: 'page-alternate-link-X-default',
        rel: 'alternate',
        href: fullPageUrl,
        hrefLang: 'X-default',
      },
    ],
    metaTags: isIOS(userAgent) ? metaTagsIOS : [],
  };
};
