import pxToRem from './pxToRem';

const multiplier = (input: number) => input * 2;

export const sizePx = (value: number): PixelSize => `${multiplier(value)}px`;

export const sizeRem = (value: number): RemSize => pxToRem(multiplier(value));

type PixelSize = `${number}px`;
type RemSize = `${number}rem`;
