const colors = {
  darkRed: '#830B25',
  red200: '#ca143b',
  red100: '#ff1749',
  red75: '#ff5177',
  red50: '#ff8ba4',
  red25: '#ffc5d1',
  skyBlue100: '#8DC8E8',
  skyBlue25: '#D2EDFC',
  darkBlue: '#52829C',
  darkBlue200: '#05172b',
  darkBlue100: '#15212f',
  darkBlue75: '#505963',
  darkBlue50: '#8a9097',
  darkBlue25: '#c4c7cb',
  grey200: '#c8c1b7',
  grey100: '#d7d2cb',
  grey75: '#e1ded8',
  grey50: '#ebe8e5',
  grey25: '#f5f4f2',
  grey: '#fafafa',
  white: '#ffffff',
  black: '#000000',
  darkGreen: '#2F5D57',
  green100: '#7CE0D3',
  green25: '#C5E5E1',
  darkYellow: '#CBC165',
  yellow100: '#FFF38A',
  yellow25: '#F9EECE',
  obsidian: '#000000',
  lightOrange: '#F4D0C0',
} as const;

export default colors;
