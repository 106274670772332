import type {BasketReducerProps} from './typings';
import {
  APPLY_PROMO_CODE_FAILURE,
  APPLY_PROMO_CODE_REQUEST,
  APPLY_PROMO_CODE_SUCCESS,
  LOAD_BASKET_FAILURE,
  LOAD_BASKET_REQUEST,
  LOAD_BASKET_SUCCESS,
  REMOVE_BASKETED_ITEM_FAILURE,
  REMOVE_BASKETED_ITEM_REQUEST,
  REMOVE_BASKETED_ITEM_SUCCESS,
  STATUS,
  UPDATE_BASKET_FAILURE,
  UPDATE_BASKET_REQUEST,
  UPDATE_BASKET_SUCCESS,
} from '../actionTypes';
import {localMessages} from '@/utils/basket/content';

const initialState: BasketReducerProps = {
  status: {
    basket: STATUS.IDLE,
    promo: STATUS.IDLE,
  },
  data: null,
  error: {
    basket: null,
    promo: null,
  },
};

const basketReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_BASKET_REQUEST:
      return {...state, status: {...state.status, basket: STATUS.LOADING}};
    case LOAD_BASKET_SUCCESS:
    case UPDATE_BASKET_SUCCESS:
    case REMOVE_BASKETED_ITEM_SUCCESS:
      return {
        status: {...state.status, basket: STATUS.SUCCEEDED},
        data: {...action.response.data},
        error: {...state.error, basket: null},
      };
    case LOAD_BASKET_FAILURE:
    case UPDATE_BASKET_FAILURE:
    case REMOVE_BASKETED_ITEM_FAILURE:
      if (action.error.code === 'ERR_NETWORK' || action.error.code === 'ECONNABORTED') {
        return {
          ...state,
          status: {...state.status, basket: STATUS.FAILED},
          error: {
            ...state.error,
            basket: action.type === UPDATE_BASKET_FAILURE ? localMessages.updateBasketFail : null,
          },
        };
      } else {
        return {
          ...state,
          status: {...state.status, basket: STATUS.FAILED},
          error: {...state.error, basket: action.error.response.data.message},
        };
      }
    case UPDATE_BASKET_REQUEST:
    case REMOVE_BASKETED_ITEM_REQUEST:
      return {...state, status: {...state.status, basket: STATUS.UPDATING}};
    case APPLY_PROMO_CODE_REQUEST:
      return {...state, status: {...state.status, promo: STATUS.LOADING}};
    case APPLY_PROMO_CODE_SUCCESS:
      return {
        status: {...state.status, promo: STATUS.SUCCEEDED},
        data: {...action.response.data},
        error: {...state.error, promo: null},
      };
    case APPLY_PROMO_CODE_FAILURE:
      if (action.error.code === 'ERR_NETWORK' || action.error.code === 'ECONNABORTED') {
        return {
          ...state,
          status: {...state.status, promo: STATUS.FAILED},
          error: {...state.error, promo: localMessages.applyPromoFail},
        };
      } else {
        return {
          ...state,
          status: {...state.status, promo: STATUS.FAILED},
          error: {...state.error, promo: action.error.response.data.message},
        };
      }
    default:
      return {...state};
  }
};

export default basketReducer;

export const selectBasketState = (state): BasketReducerProps => state.basket;
