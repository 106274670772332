import type {IconType} from '@/atoms/Icon/typings';

/**
 * @description generates the en-GB title parts based on the user's first name and basket count.
 * because the basket may be empty, the second part of the title is optional. This is needed to
 * because the second part of the title displayed on a new line on mobile devices.
 * @param {Object} param parameters object.
 * @param {string|null} param.userFirstName user's first name if available.
 * @param {number} param.basketCount number of items in the basket.
 * @return {[string, (string|undefined)]} the tuple of page title parts.
 */
export const getPageTitleContent = ({
  userFirstName,
  basketCount,
}: {
  userFirstName: string | null;
  basketCount: number;
}): [string, string?] => {
  if (basketCount === 0) {
    return userFirstName?.length
      ? [`${userFirstName}, your basket is empty`]
      : ['Your basket is empty'];
  }

  return userFirstName?.length
    ? [`${userFirstName}, ${basketCount} item${basketCount > 1 ? 's' : ''} `, 'in your basket']
    : [`${basketCount} item${basketCount > 1 ? 's' : ''} `, 'in your basket'];
};

export const basketUspContent = [
  {
    id: 'money-back-guarantee',
    icon: null,
    image: {
      src: '/img/v2/shield2.svg',
      width: 24,
      height: 29,
      alt: 'shield',
    },
    title: 'Money back guarantee',
    subTitle: 'We guarantee satisfaction with our free, no questions asked returns policy.',
  },
  {
    id: 'secure-payments',
    icon: {type: 'card' as IconType},
    image: null,
    title: 'Secure payments',
    subTitle: 'All payments are 100% secured through our trusted partners.',
  },
  {
    id: 'dedicated-support',
    icon: {type: 'comment' as IconType, fontSize: 20},
    image: null,
    title: 'Dedicated support',
    subTitle: 'Have total peace of mind, knowing our Support Team is always here to help.',
  },
];

export const localMessages = {
  itemDeleteFail: 'We are unable to remove this item. Try again later.',
  updateBasketFail: 'We are unable to update your basket information. Please, try again later.',
  applyPromoFail: `There was a network error. Your promo code wasn't applied. Please try again later.`,
};
