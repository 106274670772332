import {CurrencySymbol, CurrencyType} from '@/redux/reducers/typings';

/**
 * @description returns correct currency symbol based on the provided code
 * @param {CurrencyType} code currency code string
 * @return {CurrencySymbol} currency symbol
 */
export const currencyCodeToSymbol = (code: CurrencyType = 'GBP'): CurrencySymbol => {
  const map = {
    GBP: '£',
    USD: '$',
    AUD: 'A$',
    EUR: '€',
    CAD: 'C$',
  } as const;

  return map[code];
};

/**
 * @description converts price string to number and removes all instances of comma
 * @param {string} price input price without symbols
 * @return {number} float result
 */
export const priceStringToFloat = (price: string): number => {
  return parseFloat(price.replace(/,/g, ''));
};
