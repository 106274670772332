import {useEffect, useRef} from 'react';
import {useSelector} from 'react-redux';

import useLocalStorage from '@/hooks/use-local-storage/use-local-storage';
import {selectUserSettings} from '@/redux/reducers/userSettingsReducer';
import {STATUS_LOADING, STATUS_SUCCEEDED} from '@/redux/actionTypes';
import {selectBasketState} from '@/redux/reducers/basketReducer';

/**
 * @description custom hook to persist the basket status in `localStorage`
 * listens to the redux basket state and userSettings state for `basket_count` value
 * @return {[(boolean|null), Function]} a tuple, basket status boolean empty or not,
 * `null` while value is read from `localStorage`, and function to set that value
 */
const usePersistBasketStatus = (): [
  boolean | null,
  (value: boolean | ((val: boolean | null) => boolean | null)) => void,
] => {
  const [basketEmpty, setBasketEmpty] = useLocalStorage<null | boolean>('basketEmpty', null);
  const {data: userSettings, status: userSettingsStatus} = useSelector(selectUserSettings);
  const {data: basket, status: basketStatus} = useSelector(selectBasketState);
  const mountedRef = useRef(true);

  const isLoading = basketStatus.basket === STATUS_LOADING;
  const isUserSettingsBasketEmpty =
    userSettingsStatus === STATUS_SUCCEEDED ? userSettings.basket_count === 0 : null;

  /**
   * @description tracking the mounted status of the component
   */
  useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false; // on component/page unmount
    };
  }, []);

  /**
   * @description updates the basket status in localStorage when `userSettings.basket_count` is updated
   */
  useEffect(() => {
    if (isUserSettingsBasketEmpty === null) return;

    mountedRef.current && setBasketEmpty(isUserSettingsBasketEmpty);
  }, [isUserSettingsBasketEmpty]);

  /**
   * @description updates the basket status in localStorage when `basket` is updated
   */
  useEffect(() => {
    if (isLoading || !basket) return;

    mountedRef.current && setBasketEmpty(basket.basket_count === 0);
  }, [basket, isLoading]);

  return [basketEmpty, setBasketEmpty];
};

export default usePersistBasketStatus;
