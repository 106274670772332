import {useState, useEffect} from 'react';

/**
 * @description custom hook to persist a value in localStorage
 * @param {string} key a key to store the value in localStorage
 * @param {T} initialValue the initial value to use if no value is found in localStorage
 * @return {[T, Function]} the tuple, a stateful value and a function to update it.
 */
const useLocalStorage = <T>(
  key: string,
  initialValue: T,
): [T, (value: T | ((val: T) => T)) => void] => {
  const [storedValue, setStoredValue] = useState<T>(() => initialValue);

  /**
   * @description sets the value in localStorage,
   * in order to prevent any uncontrolled updates,
   * ensure that `setValue` is NOT called unconditionally,
   * instead it should be called based on specific conditions or user interactions.
   * @example
   * const MyComponent = () => {
   *   const [value, setValue] = useLocalStorage('myKey', 'initialValue');
   *   const handleClick = () => {
   *     setValue('newValue');
   *   };
   *
   *  return (
   *    <div>
   *      <div>{value}</div>
   *      <button onClick={handleClick}>Update Value</button>
   *    </div>
   *  );
   * };
   * @param {T | Function} value to store in localStorage
   * @return {undefined} a procedure/effect function
   */
  const setValue = (value: T | ((val: T) => T)) => {
    setStoredValue((currentStoredValue) => {
      try {
        const valueToStore = value instanceof Function ? value(currentStoredValue) : value;
        if (valueToStore === null || typeof valueToStore === 'undefined') {
          window.localStorage.removeItem(key);
        } else {
          window.localStorage.setItem(key, JSON.stringify(valueToStore));
        }

        return valueToStore;
      } catch (error) {
        console.error('Error writing to localStorage:', error);

        return currentStoredValue;
      }
    });
  };

  /**
   * @description syncs the state with localStorage
   */
  useEffect(() => {
    const syncWithLocalStorage = () => {
      try {
        const item = window.localStorage.getItem(key);
        setStoredValue(item ? JSON.parse(item) : initialValue);
      } catch (error) {
        console.error('Error reading from localStorage:', error);
      }
    };

    syncWithLocalStorage();
  }, [key]);

  return [storedValue, setValue];
};

export default useLocalStorage;
