/* eslint-disable camelcase */
import type {StatusType} from './typings';

import {
  STATUS_IDLE,
  STATUS_LOADING,
  STATUS_SUCCEEDED,
  STATUS_FAILED,
  LOAD_USER_SETTINGS_REQUEST,
  LOAD_USER_SETTINGS_SUCCESS,
  LOAD_USER_SETTINGS_FAILURE,
  SAVE_USER_SETTINGS_REQUEST,
  SAVE_USER_SETTINGS_SUCCESS,
  SAVE_USER_SETTINGS_FAILURE,
  STATUS_UPDATING,
  INCREASE_BASKET_COUNT,
  SET_BASKET_COUNT,
  OVERRIDE_USER_CURRENCY,
  SWITCH_CURRENCY,
  RESET_OVERRIDDEN_CURRENCY,
} from '../actionTypes';

const userSettingsInitialState: UserSettingsInitialState = {
  status: STATUS_IDLE,
  authenticated: false,
  currencySettings: {
    user: {
      currency: null,
      currency_symbol: null,
    },
    url: {
      currency: null,
      currency_symbol: null,
    },
  },
  data: {
    basket_count: 0,
    unread_count: 0,
    review_count: 0,
    currency: 'GBP',
    currency_symbol: '£',
    collections_enabled: false,
    country: 'GB',
    country_display: 'United Kingdom',
    display_units: 'cm',
    language: 'en-gb',
    language_display: 'British English',
    psychobar: null,
    credit_data: {
      display_text: '',
      url: '',
    },
    dislike_collective: null,
  },
  error: null,
};

const userSettingsReducer = (state = userSettingsInitialState, action) => {
  switch (action.type) {
    case INCREASE_BASKET_COUNT:
      return {
        ...state,
        data: {...state.data, basket_count: state.data.basket_count + action.payload},
      };
    case SET_BASKET_COUNT:
      return {
        ...state,
        data: {...state.data, basket_count: action.payload},
      };
    case LOAD_USER_SETTINGS_REQUEST:
      return {...state, status: STATUS_LOADING};
    case LOAD_USER_SETTINGS_SUCCESS:
      if (state.currencySettings.url.currency && !state.authenticated) {
        const keepCurrency = state.data.currency;
        const keepCurrencySymbol = state.data.currency_symbol;
        return {
          ...state,
          status: STATUS_SUCCEEDED,
          data: {
            ...state.data,
            ...action.response.data,
            currency: keepCurrency,
            currency_symbol: keepCurrencySymbol,
          },
        };
      } else {
        return {
          ...state,
          status: STATUS_SUCCEEDED,
          data: {...state.data, ...action.response.data},
          currencySettings: {
            ...state.currencySettings,
            user: {
              currency: action.response.data.currency,
              currency_symbol: action.response.data.currency_symbol,
            },
          },
        };
      }
    case LOAD_USER_SETTINGS_FAILURE:
      return {...state, status: STATUS_FAILED, error: action.error};
    case SAVE_USER_SETTINGS_REQUEST:
      return {...state, status: STATUS_UPDATING};
    case SAVE_USER_SETTINGS_SUCCESS:
      return {
        ...state,
        status: STATUS_SUCCEEDED,
        data: {...state.data, ...action.response.data},
        currencySettings: {
          user: {
            currency: action.response.data.currency,
            currency_symbol: action.response.data.currency_symbol,
          },
          url: {...userSettingsInitialState.currencySettings.url},
        },
      };
    case SAVE_USER_SETTINGS_FAILURE:
      return {...state, status: STATUS_FAILED, error: action.error};
    case OVERRIDE_USER_CURRENCY:
      return {
        ...state,
        data: {...state.data, ...action.payload},
        currencySettings: {...state.currencySettings, url: {...action.payload}},
      };
    case RESET_OVERRIDDEN_CURRENCY:
      return {
        ...state,
        currencySettings: {
          ...state.currencySettings,
          url: {...userSettingsInitialState.currencySettings.url},
        },
      };
    case SWITCH_CURRENCY:
      const currency = action.payload.authenticated
        ? state.currencySettings.user.currency
        : state.currencySettings.url.currency;
      const currencySymbol = action.payload.authenticated
        ? state.currencySettings.user.currency_symbol
        : state.currencySettings.url.currency_symbol;

      if (!currency) {
        return {...state, authenticated: action.payload.authenticated};
      }

      return {
        ...state,
        data: {...state.data, currency: currency, currency_symbol: currencySymbol},
        authenticated: action.payload.authenticated,
      };
    default:
      return {...state};
  }
};

export default userSettingsReducer;

// Export a reusable selector so state structure is abstracted for components
export const selectUserSettingsData = (state): UserSettingsData => state.userSettings.data;
export const selectUserSettingsStatus = (state): StatusType => state.userSettings.status;
export const selectUserSettings = (state): UserSettingsInitialState => state.userSettings;

export type CurrencyType = 'GBP' | 'USD' | 'EUR' | 'CAD' | 'AUD';
export type CurrencySymbolType = '£' | '$' | '€' | 'C$' | 'A$';
type UnitType = 'cm' | 'in';

interface UserSettingsData {
  basket_count: number;
  unread_count: number;
  review_count: number;
  currency: CurrencyType;
  currency_symbol: CurrencySymbolType;
  collections_enabled: boolean;
  country: string;
  country_display: string;
  display_units: UnitType;
  language: string;
  language_display: string;
  psychobar: null;
  credit_data: {
    display_text: string;
    url: string;
  };
  dislike_collective: null;
}

interface CurrencySettings {
  currency: CurrencyType | null;
  currency_symbol: CurrencySymbolType | null;
}

interface CurrencySettingsObject {
  user: CurrencySettings;
  url: CurrencySettings;
}

interface UserSettingsInitialState {
  status: StatusType;
  authenticated: boolean;
  currencySettings: CurrencySettingsObject;
  data: UserSettingsData;
  error: string | null;
}
