// Async actions status
export const STATUS_IDLE = 'idle';
export const STATUS_LOADING = 'loading';
export const STATUS_UPDATING = 'updating';
export const STATUS_SUCCEEDED = 'succeeded';
export const STATUS_FAILED = 'failed';

// product actions
export const GET_PRODUCT_REQUEST = 'GET_PRODUCT_REQUEST';
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_FAILURE = 'GET_PRODUCT_FAILURE';
export const SET_CUSTOMER_INFO = 'SET_CUSTOMER_INFO';
export const SET_FAVOURITES_ARRAY = 'SET_FAVOURITES_ARRAY';
export const SET_CUSTOMER_FAVOURITES = 'SET_CUSTOMER_FAVOURITES';
export const SET_FAVOURITE_COUNT = 'SET_FAVOURITE_COUNT';
export const FAVOURITE_INCREMENT = 'FAVOURITE_INCREMENT';
export const FAVOURITE_DECREMENT = 'FAVOURITE_DECREMENT';
export const FAVOURITE_TOGGLE = 'FAVOURITE_TOGGLE';
export const ADD_TO_COLLECTION = 'ADD_TO_COLLECTION';
export const REMOVE_FROM_COLLECTION = 'REMOVE_FROM_COLLECTION';
export const SET_ARTIST_ID = 'SET_ARTIST_ID';
export const LOAD_SHIPPING_DATA_REQUEST = 'LOAD_SHIPPING_DATA_REQUEST';
export const LOAD_SHIPPING_DATA_SUCCESS = 'LOAD_SHIPPING_DATA_SUCCESS';
export const LOAD_SHIPPING_DATA_FAILURE = 'LOAD_SHIPPING_DATA_FAILURE';
export const CLEAR_SHIPPING_DATA = 'CLEAR_SHIPPING_DATA';
export const LOAD_AUTO_DETECTED_TAGS_REQUEST = 'LOAD_AUTO_DETECTED_TAGS_REQUEST';
export const LOAD_AUTO_DETECTED_TAGS_SUCCESS = 'LOAD_AUTO_DETECTED_TAGS_SUCCESS';
export const LOAD_AUTO_DETECTED_TAGS_FAILURE = 'LOAD_AUTO_DETECTED_TAGS_FAILURE';
export const PROMOTE_PRODUCT_REQUEST = 'PROMOTE_PRODUCT_REQUEST';
export const PROMOTE_PRODUCT_SUCCESS = 'PROMOTE_PRODUCT_SUCCESS';
export const PROMOTE_PRODUCT_FAILURE = 'PROMOTE_PRODUCT_FAILURE';
export const GET_PRODUCT_THEMES_REQUEST = 'GET_PRODUCT_THEMES_REQUEST';
export const GET_PRODUCT_THEMES_SUCCESS = 'GET_PRODUCT_THEMES_SUCCESS';
export const GET_PRODUCT_THEMES_FAILURE = 'GET_PRODUCT_THEMES_FAILURE';
export const ADD_PRODUCT_THEME_REQUEST = 'ADD_PRODUCT_THEME_REQUEST';
export const ADD_PRODUCT_THEME_SUCCESS = 'ADD_PRODUCT_THEME_SUCCESS';
export const ADD_PRODUCT_THEME_FAILURE = 'ADD_PRODUCT_THEME_FAILURE';
export const DELETE_PRODUCT_THEME_REQUEST = 'DELETE_PRODUCT_THEME_REQUEST';
export const DELETE_PRODUCT_THEME_SUCCESS = 'DELETE_PRODUCT_THEME_SUCCESS';
export const DELETE_PRODUCT_THEME_FAILURE = 'DELETE_PRODUCT_THEME_FAILURE';
export const GET_PRODUCT_CAMPAIGNS_REQUEST = 'GET_PRODUCT_CAMPAIGNS_REQUEST';
export const GET_PRODUCT_CAMPAIGNS_SUCCESS = 'GET_PRODUCT_CAMPAIGNS_SUCCESS';
export const GET_PRODUCT_CAMPAIGNS_FAILURE = 'GET_PRODUCT_CAMPAIGNS_FAILURE';
export const ADD_PRODUCT_CAMPAIGN_REQUEST = 'ADD_PRODUCT_CAMPAIGN_REQUEST';
export const ADD_PRODUCT_CAMPAIGN_SUCCESS = 'ADD_PRODUCT_CAMPAIGN_SUCCESS';
export const ADD_PRODUCT_CAMPAIGN_FAILURE = 'ADD_PRODUCT_CAMPAIGN_FAILURE';
export const DELETE_PRODUCT_CAMPAIGN_REQUEST = 'DELETE_PRODUCT_CAMPAIGN_REQUEST';
export const DELETE_PRODUCT_CAMPAIGN_SUCCESS = 'DELETE_PRODUCT_CAMPAIGN_SUCCESS';
export const DELETE_PRODUCT_CAMPAIGN_FAILURE = 'DELETE_PRODUCT_CAMPAIGN_FAILURE';
export const GET_PRODUCT_COLLECTIONS_REQUEST = 'GET_PRODUCT_COLLECTIONS_REQUEST';
export const GET_PRODUCT_COLLECTIONS_SUCCESS = 'GET_PRODUCT_COLLECTIONS_SUCCESS';
export const GET_PRODUCT_COLLECTIONS_FAILURE = 'GET_PRODUCT_COLLECTIONS_FAILURE';
export const ADD_PRODUCT_COLLECTION_REQUEST = 'ADD_PRODUCT_COLLECTION_REQUEST';
export const ADD_PRODUCT_COLLECTION_SUCCESS = 'ADD_PRODUCT_COLLECTION_SUCCESS';
export const ADD_PRODUCT_COLLECTION_FAILURE = 'ADD_PRODUCT_COLLECTION_FAILURE';
export const DELETE_PRODUCT_COLLECTION_REQUEST = 'DELETE_PRODUCT_COLLECTION_REQUEST';
export const DELETE_PRODUCT_COLLECTION_SUCCESS = 'DELETE_PRODUCT_COLLECTION_SUCCESS';
export const DELETE_PRODUCT_COLLECTION_FAILURE = 'DELETE_PRODUCT_COLLECTION_FAILURE';
export const CREATE_NEW_COLLECTION_REQUEST = 'CREATE_NEW_COLLECTION_REQUEST';
export const CREATE_NEW_COLLECTION_SUCCESS = 'CREATE_NEW_COLLECTION_SUCCESS';
export const CREATE_NEW_COLLECTION_FAILURE = 'CREATE_NEW_COLLECTION_FAILURE';
export const GET_RECENTLY_VIEWED_REQUEST = 'GET_RECENTLY_VIEWED_REQUEST';
export const GET_RECENTLY_VIEWED_SUCCESS = 'GET_RECENTLY_VIEWED_SUCCESS';
export const GET_RECENTLY_VIEWED_FAILURE = 'GET_RECENTLY_VIEWED_FAILURE';
export const GET_MORE_BY_SAME_ARTIST_REQUEST = 'GET_MORE_BY_SAME_ARTIST_REQUEST';
export const GET_MORE_BY_SAME_ARTIST_SUCCESS = 'GET_MORE_BY_SAME_ARTIST_SUCCESS';
export const GET_MORE_BY_SAME_ARTIST_FAILURE = 'GET_MORE_BY_SAME_ARTIST_FAILURE';
export const GET_PRODUCT_BUNDLE_REQUEST = 'GET_PRODUCT_BUNDLE_REQUEST';
export const GET_PRODUCT_BUNDLE_SUCCESS = 'GET_PRODUCT_BUNDLE_SUCCESS';
export const GET_PRODUCT_BUNDLE_FAILURE = 'GET_PRODUCT_BUNDLE_FAILURE';
export const LOAD_PRODUCT_PRINTS_REQUEST = 'LOAD_PRODUCT_PRINTS_REQUEST';
export const LOAD_PRODUCT_PRINTS_SUCCESS = 'LOAD_PRODUCT_PRINTS_SUCCESS';
export const LOAD_PRODUCT_PRINTS_FAILURE = 'LOAD_PRODUCT_PRINTS_FAILURE';
export const SET_PRODUCT_PRINTS = 'SET_PRODUCT_PRINTS';

// product favourite actions (new)
export const LOAD_PRODUCT_FAVOURITES_REQUEST = 'LOAD_PRODUCT_FAVOURITES_REQUEST';
export const LOAD_PRODUCT_FAVOURITES_SUCCESS = 'LOAD_PRODUCT_FAVOURITES_SUCCESS';
export const LOAD_PRODUCT_FAVOURITES_FAILURE = 'LOAD_PRODUCT_FAVOURITES_FAILURE';

export const SAVE_PRODUCT_FAVOURITE_REQUEST = 'SAVE_PRODUCT_FAVOURITE_REQUEST';
export const SAVE_PRODUCT_FAVOURITE_SUCCESS = 'SAVE_PRODUCT_FAVOURITE_SUCCESS';
export const SAVE_PRODUCT_FAVOURITE_FAILURE = 'SAVE_PRODUCT_FAVOURITE_FAILURE';

// PDP favourites
// TODO: clean up all favourites
export const SET_FAVOURITE_REQUEST = 'SET_FAVOURITE_REQUEST';
export const SET_FAVOURITE_SUCCESS = 'SET_FAVOURITE_SUCCESS';
export const SET_FAVOURITE_FAILURE = 'SET_FAVOURITE_FAILURE';

// Load user settings Actions
export const LOAD_USER_SETTINGS_REQUEST = 'LOAD_USER_SETTINGS_REQUEST';
export const LOAD_USER_SETTINGS_SUCCESS = 'LOAD_USER_SETTINGS_SUCCESS';
export const LOAD_USER_SETTINGS_FAILURE = 'LOAD_USER_SETTINGS_FAILURE';
export const INCREASE_BASKET_COUNT = 'INCREASE_BASKET_COUNT';
export const SET_BASKET_COUNT = 'SET_BASKET_COUNT';
export const OVERRIDE_USER_CURRENCY = 'OVERRIDE_USER_CURRENCY';
export const RESET_OVERRIDDEN_CURRENCY = 'RESET_OVERRIDDEN_CURRENCY';
export const SWITCH_CURRENCY = 'SWITCH_CURRENCY';

// Basket actions
export const LOAD_BASKET_REQUEST = 'LOAD_BASKET_REQUEST';
export const LOAD_BASKET_SUCCESS = 'LOAD_BASKET_SUCCESS';
export const LOAD_BASKET_FAILURE = 'LOAD_BASKET_FAILURE';
export const UPDATE_BASKET_REQUEST = 'UPDATE_BASKET_REQUEST';
export const UPDATE_BASKET_SUCCESS = 'UPDATE_BASKET_SUCCESS';
export const UPDATE_BASKET_FAILURE = 'UPDATE_BASKET_FAILURE';
export const APPLY_PROMO_CODE_REQUEST = 'APPLY_PROMO_CODE_REQUEST';
export const APPLY_PROMO_CODE_SUCCESS = 'APPLY_PROMO_CODE_SUCCESS';
export const APPLY_PROMO_CODE_FAILURE = 'APPLY_PROMO_CODE_FAILURE';
export const REMOVE_BASKETED_ITEM_REQUEST = 'REMOVE_BASKETED_ITEM_REQUEST';
export const REMOVE_BASKETED_ITEM_SUCCESS = 'REMOVE_BASKETED_ITEM_SUCCESS';
export const REMOVE_BASKETED_ITEM_FAILURE = 'REMOVE_BASKETED_ITEM_FAILURE';
export const SAVE_FOR_LATER_REQUEST = 'SAVE_FOR_LATER_REQUEST';
export const SAVE_FOR_LATER_SUCCESS = 'SAVE_FOR_LATER_SUCCESS';
export const SAVE_FOR_LATER_FAILURE = 'SAVE_FOR_LATER_FAILURE';
export const MOVE_TO_BASKET_REQUEST = 'MOVE_TO_BASKET_REQUEST';
export const MOVE_TO_BASKET_SUCCESS = 'MOVE_TO_BASKET_SUCCESS';
export const MOVE_TO_BASKET_FAILURE = 'MOVE_TO_BASKET_FAILURE';

// Save user settings Actions
export const SAVE_USER_SETTINGS_REQUEST = 'SAVE_USER_SETTINGS_REQUEST';
export const SAVE_USER_SETTINGS_SUCCESS = 'SAVE_USER_SETTINGS_SUCCESS';
export const SAVE_USER_SETTINGS_FAILURE = 'SAVE_USER_SETTINGS_FAILURE';

// Setting Options Actions
export const LOAD_SETTINGS_OPTIONS_REQUEST = 'LOAD_SETTINGS_OPTIONS_REQUEST';
export const LOAD_SETTINGS_OPTIONS_SUCCESS = 'LOAD_SETTINGS_OPTIONS_SUCCESS';
export const LOAD_SETTINGS_OPTIONS_FAILURE = 'LOAD_SETTINGS_OPTIONS_FAILURE';

// Currency conversion rate Actions
export const LOAD_CURRENCY_RATES_REQUEST = 'LOAD_CURRENCY_RATES_REQUEST';
export const LOAD_CURRENCY_RATES_SUCCESS = 'LOAD_CURRENCY_RATES_SUCCESS';
export const LOAD_CURRENCY_RATES_FAILURE = 'LOAD_CURRENCY_RATES_FAILURE';

// Product recommendations Actions
export const LOAD_PRODUCT_RECS_REQUEST = 'LOAD_PRODUCT_RECS_REQUEST';
export const LOAD_PRODUCT_RECS_SUCCESS = 'LOAD_PRODUCT_RECS_SUCCESS';
export const LOAD_PRODUCT_RECS_FAILURE = 'LOAD_PRODUCT_RECS_FAILURE';

// Gallery Tracking Actions
export const GALLERY_FILTER_USED_BY_USER = 'GALLERY_FILTER_USED_BY_USER';
export const GALLERY_PAGINATION_USED_BY_USER = 'GALLERY_PAGINATION_USED_BY_USER';
export const GALLERY_SORT_USED_BY_USER = 'GALLERY_SORT_USED_BY_USER';
export const GALLERY_URL_CHANGED = 'GALLERY_URL_CHANGED';

// Filter settings Actions
export const SET_ACTIVE_FILTERS = 'SET_ACTIVE_FILTERS';
export const SET_CATEGORY_FILTER = 'SET_CATEGORY_FILTER';
export const SET_COLOR_FILTER = 'SET_COLOR_FILTER';
export const SET_FRAMED_FILTER = 'SET_FRAMED_FILTER';
export const SET_FREE_SHIPPING_FILTER = 'SET_FREE_SHIPPING_FILTER';
export const SET_PRICE_FILTER = 'SET_PRICE_FILTER';
export const SET_SIZE_FILTER = 'SET_SIZE_FILTER';
export const SET_PRINTS_FILTER = 'SET_PRINTS_FILTER';
export const SET_SHAPE_FILTER = 'SET_SHAPE_FILTER';
export const SET_SHIPS_TO_FILTER = 'SET_SHIPS_TO_FILTER';
export const SET_SHIPS_FROM_FILTER = 'SET_SHIPS_FROM_FILTER';
export const CHANGE_SHIPS_TO_COUNTRY = 'CHANGE_SHIPS_TO_COUNTRY';
export const SET_SORT_FILTER = 'SET_SORTING_FILTER';
export const SET_STYLE_FILTER = 'SET_STYLE_FILTER';
export const SET_SUBJECT_FILTER = 'SET_SUBJECT_FILTER';
export const SET_KEYWORD_FILTER = 'SET_KEYWORD_FILTER';
export const SET_URL_FILTERS = 'SET_URL_FILTERS';
export const SET_ON_SALE_FILTER = 'SET_ON_SALE_FILTER';
export const RESET_CATEGORY_FILTER = 'RESET_CATEGORY_FILTER';
export const RESET_COLOR_FILTER = 'RESET_COLOR_FILTER';
export const RESET_FRAMED_FILTER = 'SET_FRAMED_FILTER';
export const RESET_PRICE_FILTER = 'RESET_PRICE_FILTER';
export const RESET_SIZE_FILTER = 'RESET_SIZE_FILTER';
export const RESET_SUBJECT_FILTER = 'RESET_SUBJECT_FILTER';
export const RESET_PRINTS_FILTER = 'SET_PRINTS_FILTER';
export const RESET_SHAPE_FILTER = 'RESET_SHAPE_FILTER';
export const RESET_SHIPS_TO_FILTER = 'RESET_SHIPS_TO_FILTER';
export const RESET_SHIPS_FROM_FILTER = 'RESET_SHIPS_FROM_FILTER';
export const RESET_SORT_FILTER = 'RESET_SORTING_FILTER';
export const RESET_STYLE_FILTER = 'RESET_STYLE_FILTER';
export const RESET_KEYWORD_FILTER = 'RESET_KEYWORD_FILTER';
export const RESET_ALL_FILTERS = 'RESET_ALL_FILTERS';
export const LOAD_PRICE_BARS_REQUEST = 'LOAD_PRICE_BARS_REQUEST';
export const LOAD_PRICE_BARS_SUCCESS = 'LOAD_PRICE_BARS_SUCCESS';
export const LOAD_PRICE_BARS_FAILURE = 'LOAD_PRICE_BARS_FAILURE';
export const SET_PAGINATION_SETTINGS = 'SET_PAGINATION_SETTINGS';

// PLP filter gallery results Actions
export const LOAD_SEARCH_PREVIEW_REQUEST = 'LOAD_SEARCH_PREVIEW_REQUEST';
export const LOAD_SEARCH_PREVIEW_SUCCESS = 'LOAD_SEARCH_PREVIEW_SUCCESS';
export const LOAD_SEARCH_PREVIEW_FAILURE = 'LOAD_SEARCH_PREVIEW_FAILURE';
export const LOAD_GALLERY_SEARCH_REQUEST = 'LOAD_GALLERY_SEARCH_REQUEST';
export const LOAD_GALLERY_SEARCH_SUCCESS = 'LOAD_GALLERY_SEARCH_SUCCESS';
export const LOAD_GALLERY_SEARCH_FAILURE = 'LOAD_GALLERY_SEARCH_FAILURE';
export const CLEAR_GALLERY_SEARCH_RESULTS = 'CLEAR_GALLERY_SEARCH_RESULTS';
export const SEED_GALLERY_SEARCH_DATA_REQUEST = 'SEED_GALLERY_SEARCH_DATA_REQUEST';
export const SEED_GALLERY_SEARCH_DATA_SUCCESS = 'SEED_GALLERY_SEARCH_DATA_SUCCESS';
export const SEED_GALLERY_SEARCH_DATA_FAILURE = 'SEED_GALLERY_SEARCH_DATA_FAILURE';

// PLP SEO Text
export const LOAD_SEO_TEXT_REQUEST = 'LOAD_SEO_TEXT_REQUEST';
export const LOAD_SEO_TEXT_SUCCESS = 'LOAD_SEO_TEXT_SUCCESS';
export const LOAD_SEO_TEXT_FAILURE = 'LOAD_SEO_TEXT_FAILURE';
export const SET_SEO_TEXT = 'SET_SEO_TEXT';

// Tracking Events
export const PAGE_VIEW_REQUEST = 'PAGE_VIEW_REQUEST';
export const PAGE_VIEW_SUCCESS = 'PAGE_VIEW_SUCCESS';
export const PAGE_VIEW_FAILURE = 'PAGE_VIEW_FAILURE';
export const IMPRESSIONS_REQUEST = 'IMPRESSIONS_REQUEST';
export const IMPRESSIONS_SUCCESS = 'IMPRESSIONS_SUCCESS';
export const IMPRESSIONS_FAILURE = 'IMPRESSIONS_FAILURE';
export const VISIT_REQUEST = 'VISIT_REQUEST';
export const VISIT_SUCCESS = 'VISIT_SUCCESS';
export const VISIT_FAILURE = 'VISIT_FAILURE';
export const PRODUCT_CLICK_REQUEST = 'PRODUCT_CLICK_REQUEST';
export const PRODUCT_CLICK_SUCCESS = 'PRODUCT_CLICK_SUCCESS';
export const PRODUCT_CLICK_FAILURE = 'PRODUCT_CLICK_FAILURE';

// Search Actions
export const LOAD_SEARCH_AUTOCOMPLETE_REQUEST = 'LOAD_SEARCH_AUTOCOMPLETE_REQUEST';
export const LOAD_SEARCH_AUTOCOMPLETE_SUCCESS = 'LOAD_SEARCH_AUTOCOMPLETE_SUCCESS';
export const LOAD_SEARCH_AUTOCOMPLETE_FAILURE = 'LOAD_SEARCH_AUTOCOMPLETE_FAILURE';
export const LOAD_ARTIST_SEARCH_AUTOCOMPLETE_REQUEST = 'LOAD_ARTIST_SEARCH_AUTOCOMPLETE_REQUEST';
export const LOAD_ARTIST_SEARCH_AUTOCOMPLETE_SUCCESS = 'LOAD_ARTIST_SEARCH_AUTOCOMPLETE_SUCCESS';
export const LOAD_ARTIST_SEARCH_AUTOCOMPLETE_FAILURE = 'LOAD_ARTIST_SEARCH_AUTOCOMPLETE_FAILURE';

// Join Mailing List Actions
export const LOAD_JOIN_MAILING_LIST_REQUEST = 'LOAD_JOIN_MAILING_LIST_REQUEST';
export const LOAD_JOIN_MAILING_LIST_SUCCESS = 'LOAD_JOIN_MAILING_LIST_SUCCESS';
export const LOAD_JOIN_MAILING_LIST_FAILURE = 'LOAD_JOIN_MAILING_LIST_FAILURE';

// Load Discover Artist Actions
export const LOAD_ARTISTS_DISCOVER_LIST_REQUEST = 'LOAD_ARTISTS_DISCOVER_LIST_REQUEST';
export const LOAD_ARTISTS_DISCOVER_LIST_SUCCESS = 'LOAD_ARTISTS_DISCOVER_LIST_SUCCESS';
export const LOAD_ARTISTS_DISCOVER_LIST_FAILURE = 'LOAD_ARTISTS_DISCOVER_LIST_FAILURE';

// Load Popular Artist Actions
export const LOAD_ARTISTS_POPULAR_LIST_REQUEST = 'LOAD_ARTISTS_POPULAR_LIST_REQUEST';
export const LOAD_ARTISTS_POPULAR_LIST_SUCCESS = 'LOAD_ARTISTS_POPULAR_LIST_SUCCESS';
export const LOAD_ARTISTS_POPULAR_LIST_FAILURE = 'LOAD_ARTISTS_POPULAR_LIST_FAILURE';

// Load Editor's Picks Actions
export const LOAD_EDITORS_PICKS_REQUEST = 'LOAD_EDITORS_PICKS_REQUEST';
export const LOAD_EDITORS_PICKS_SUCCESS = 'LOAD_EDITORS_PICKS_SUCCESS';
export const LOAD_EDITORS_PICKS_FAILURE = 'LOAD_EDITORS_PICKS_FAILURE';

// Banner Actions
export const LOAD_BANNER_REQUEST = 'LOAD_BANNER_REQUEST';
export const LOAD_BANNER_SUCCESS = 'LOAD_BANNER_SUCCESS';
export const LOAD_BANNER_FAILURE = 'LOAD_BANNER_FAILURE';

// Grouping
export const STATUS = {
  IDLE: STATUS_IDLE,
  SUCCEEDED: STATUS_SUCCEEDED,
  LOADING: STATUS_LOADING,
  UPDATING: STATUS_UPDATING,
  FAILED: STATUS_FAILED,
} as const;
