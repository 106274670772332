import {css} from 'styled-components';

export const screenReaderOnly = css`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
`;

export const globals = css`
  body {
    margin: 0;
    font-family: 'sul-sans', helvetica, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #15212f;
    text-align: left;
    background-color: #f5f4f2;
  }
  a {
    color: #15212f;
  }
  caption {
    color: #6c757d;
  }

  /**
    * classNames for assistive technologies, see example link below
    * @link https://tailwindcss.com/docs/screen-readers
   */
  .sr-only {
    ${screenReaderOnly}
  }
  .not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
`;
